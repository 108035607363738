import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import { MdClose } from "react-icons/md"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import styled from "@emotion/styled"

Modal.setAppElement(`#___gatsby`)

const customStyles = {
  overlay: {
    background: "rgba(0,0,0,0.8)",
    zIndex: 9999,
    padding: 0,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    margin: "auto",
    padding: 0,
    border: "none",
    borderRadius: "6px",
  },
}

const mobileStyles = {
  overlay: {
    background: "rgba(0,0,0,0.4)",
    zIndex: 9999,
    padding: 0,
  },
  content: {
    top: "auto",
    left: "0",
    right: "0",
    bottom: "0",
    width: "100%",
    background: "transparent",
    margin: "auto",
    padding: 0,
    border: "none",
    borderRadius: "0px",
    maxHeight: "55%",
  },
}

const customCloseStyles = {
  position: "absolute",
  right: 0,
  top: 0,
  cursor: "pointer",
  padding: 5,
}

const mobileCloseStyles = {
  position: "absolute",
  right: "5px",
  top: "5px",
  cursor: "pointer",
  padding: "3px",
  borderRadius: "50%",
  width: "22px",
  height: "22px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const Text = styled.div`
  ul {
    margin: 0px 0px 1em;
    list-style: none;
  }
  ul li {
    margin: 0px;
  }
  p {
    margin: 0px 0px 1em;
  }
`

const PriceDetails = ({ open, details, closeHandle }) => {
  const [IsOpen, setIsOpen] = useState(false)
  const [IsMobile, setIsMobile] = useState(false)
  useEffect(() => {
    open ? openModal() : closeModal()
    windowResize()
  })

  const openModal = () => {
    setIsOpen(true)
  }

  const windowResize = () => {
    var w = window.outerWidth
    if (w <= 600) {
      setIsMobile(true)
    }
    $(window).resize(() => {
      var w = window.outerWidth
      if (w <= 600) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  return (
    <Modal
      isOpen={IsOpen}
      style={IsMobile ? mobileStyles : customStyles}
      contentLabel="Modal"
      onRequestClose={() => closeHandle(false)}
    >
      {IsMobile ? (
        <div
          style={{
            background: "white",
            padding: "20px 20px 1px 20px",
            margin: "auto",
            fontSize: 13,
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          <div style={mobileCloseStyles} onClick={() => closeHandle(false)}>
            <MdClose color="#262626" size={20} />
          </div>
          <Text
            dangerouslySetInnerHTML={{
              __html: details,
            }}
          />
        </div>
      ) : (
        <div
          style={{
            background: "white",
            padding: "20px 20px 1px 20px",
            margin: "auto",
            fontSize: 13,
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          <div style={customCloseStyles} onClick={() => closeHandle(false)}>
            <MdClose color="#262626" size={20} />
          </div>
          <Text
            dangerouslySetInnerHTML={{
              __html: details,
            }}
          />
        </div>
      )}
    </Modal>
  )
}

export default PriceDetails
